import { WHIPClient, WHIPClientOptions } from "../../sdk/src/index";
import { getIceServers } from "./util";
import { Decrypt } from '../../sdk/src/decrypt'

async function ingest(client: WHIPClient, mediaStream: MediaStream) {
  const videoIngest = document.querySelector<HTMLVideoElement>("video#ingest");

  videoIngest.srcObject = mediaStream;
  await client.ingest(mediaStream);
}

async function createClient(url: string, iceConfigRemote: boolean, opts: WHIPClientOptions) {
  const client = new WHIPClient({
    endpoint: url,
    opts: opts,
  });
  if (iceConfigRemote) {
    await client.setIceServersFromEndpoint();
  }

  return client;
}

function getAuthKey() {
  let authkey;

  return authkey;
}

window.addEventListener("DOMContentLoaded", async () => {

  let inputurl = ""
  const ingestCamera =
    document.querySelector<HTMLButtonElement>("#ingest-camera");
  const ingestScreen =
    document.querySelector<HTMLButtonElement>("#ingest-screen");

  const paramNoTrickleIce = false

  
  const debug = false;
  const iceConfigRemote = !!(process.env.ICE_CONFIG_REMOTE);

  const url = new URL(window.location.href);
  const param = window.location.href.split("endpoint=")
  if (param.length> 0) {
    const cryptClass = new Decrypt(param[1])
    inputurl = new URL(cryptClass.decrypt());
  }

  ingestCamera.addEventListener("click", async () => {
    const client = await createClient(inputurl, iceConfigRemote, { 
      debug: debug, iceServers: getIceServers(), authkey: getAuthKey(), 
      noTrickleIce: paramNoTrickleIce.checked }
    );
    const mediaStream = await navigator.mediaDevices.getUserMedia({
      video: true,
      audio: true,
    });
    ingest(client, mediaStream);
  });

  ingestScreen.addEventListener("click", async () => {
    const client = await createClient(inputurl, iceConfigRemote, { 
      debug: debug, iceServers: getIceServers(), authkey: getAuthKey(), 
      noTrickleIce: paramNoTrickleIce.checked }
    );
    const mediaStream = await navigator.mediaDevices.getDisplayMedia();
    ingest(client, mediaStream);
  });

 

});
